import React, {useState, useEffect, useRef} from 'react';
import './App.scss';
import 'antd/dist/antd.css';
import moment from "moment/moment";

import Form from './components/Form'
import Message from './components/Message'
import User from './components/User'

import ErrorModal from "./components/ErrorModal";

import config from './config'
import socketio from "socket.io-client"

const socket = socketio.connect(config.socket_server.url)

export default function App () {

    const [users, setUsers] = useState([]);
    const [messages, setMessages] = useState([]);
    const [error, setError] = useState({
        enabled: true,
        message: 'Connexion au serveur ...',
        closable: false
    });

    const user_id = localStorage.getItem('user_id');
    const panel = useRef(null);

    const onCancel = () => {
        setError({
            enabled: false,
            message: '',
            closable: false
        })
    }

    useEffect(() => {

        socket.on('connect', function() {
            setError({
                enabled: false,
                message: '',
                closable: false
            })
        });

        socket.on('connect_error', function(){
            setError({
                enabled: true,
                message: 'La connexion au serveur a été interrompue',
                closable: false
            })
        });

        socket.on('server_error', function(){
            setError({
                enabled: true,
                message: 'Erreur serveur',
                closable: false
            })
        });

        socket.on('messages_limit', function({reset}){
            setError({
                enabled: true,
                message: 'Limite de messages atteinte. Prochain message possible ' + moment().to(moment(reset, "DD.MM.YYYY HH:mm:ss")) + '.',
                closable: true
            })
        });

        // First connection
        if (!user_id) {
            socket.emit('first_connection', {
                user_agent: window.navigator.userAgent
            });

            socket.on('first_connection_id', function({connection_id, connected_users, messages}) {
                localStorage.setItem('user_id', connection_id)
                setUsers(connected_users)
                setMessages(messages)
            });
        }

        // Reconnection
        else {
            socket.emit('reconnected', {
                user_id : user_id
            });
            socket.on('get_connected_users', function({connected_users, messages}) {
                setUsers(connected_users)
                setMessages(messages)
            });
        }

        socket.on('new_user', function({user_id, sessions}) {
            setUsers(users => [...users, {id: user_id, sessions: sessions}]);
        });

        socket.on('user_disconnected', function (user_id) {
            setUsers(users => users.filter(user => user.id !== parseInt(user_id)))
        });

        socket.on('new_session', function (user_id) {
            setUsers(users => users.map(user => parseInt(user.id) === parseInt(user_id) ? {...user, sessions: ++user.sessions} : user))
        });

        socket.on('delete_session', function (user_id) {
            setUsers(users => users.map(user => parseInt(user.id) === parseInt(user_id) ? {...user, sessions: --user.sessions} : user))
        });

        socket.on('new_message', function (message) {
            setMessages(messages => [...messages, message]);
            panel.current.scrollTop = panel.current.scrollHeight;
        });

    }, []);

    const addMessage = (message, type='text') => {
        socket.emit('new_message', {
            message: message,
            type: type,
            user_id: user_id
        });
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div id="users" className="col-md-1 col-sm-2 col-xs-3 text-center">
                    <span id="online-users">{ users.length }</span>
                    <span> utisateur{ (users.length <= 1) ? '' : 's'} en ligne</span>
                    <hr className="user-hr"/>
                    {users.map((user,index) => (
                        <User key={index} id={user.id} sessions={user.sessions} currentUser={user_id} />
                    ))}
                </div>
                <div id="panel" className="col-md-11 col-sm-10 col-xs-9" ref={panel}>
                    <div id="fond"/>
                    <ul id="messages">
                        {messages.map((message, index) => (
                            <Message key={index} data={message} currentUser={user_id} />
                        ))}
                    </ul>
                    <Form addMessage={addMessage} />
                </div>
            </div>
            <ErrorModal visible={error.enabled} message={error.message} closable={error.closable} onCancel={onCancel}/>
        </div>
    );
}