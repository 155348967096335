import React, {useState} from 'react';
import { Skeleton } from 'antd';

export default function SkeletonImage ({url, id, dimension, addMessage, toggleModal}) {

    const [loading, setLoading] = useState(true);

    return (
            <div>
                {
                    (loading) ?
                        <Skeleton.Avatar
                            size={'large'}
                            active={true}
                            shape={'square'}
                            style={{ width: '100%', height:dimension[1] || '200px', borderRadius: '10px', border: '1px solid #ccc'}}
                        /> : null
                }
                <img
                    style={ (loading) ? {display: 'none'} : {width: '100%', borderRadius: '10px', border: '1px solid #ccc', cursor:'pointer'}}
                    src={url}
                    alt={'gif-' + id}
                    onLoad={() => setLoading(false)}
                    onClick={() => {
                        toggleModal()
                        addMessage(url, 'gif')
                    }}
                />
            </div>
    )
}
