import React, {useEffect, useState} from 'react';
import {Divider, Modal, Spin} from "antd";
import {listTrendingGifs, searchGifs} from "../api/tenor";
import SkeletonImage from "./SkeletonImage";
import Masonry from 'react-masonry-css'

import error_image from '../img/error.png';
import empty_image from '../img/empty.png';

let timeout = 0;

export default function GifModal ({visible, toggleModal, addMessage}) {

    const [gifs, setGifs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {

        if (visible && !gifs.length) {
            setLoading(true)
            listTrendingGifs().then(response => {
                setGifs(response.data.results);
                setLoading(false)
            }).catch(error => {
                setLoading(false)
                setError(true)
                console.error(error)
            })
        }

    }, [visible])

    const handleSearch = event => {

        setLoading(true);
        setGifs([]);

        const text = event.target.value.trim();

        if(timeout) clearTimeout(timeout);

        if (text) {

            timeout = setTimeout(() => {

                searchGifs(text).then(response => {
                    if (response.data.results.length > 0){
                        setGifs(response.data.results)
                    }
                    setLoading(false);
                    setError(false)
                }).catch(error => {
                    setLoading(false)
                    setError(true)
                    console.error(error)
                })

            }, 300);
        }

        else {
            listTrendingGifs().then(response => {
                setGifs(response.data.results);
                setLoading(false)
            }).catch(error => {
                setLoading(false)
                setError(true)
                console.error(error)
            })
        }
    }

    const showResults = () => {

        if (!loading && !error && gifs.length ) {

            return (
                <Masonry
                    breakpointCols={{
                        default : 5,
                        1200: 4,
                        992: 3,
                        768: 2,
                        350 : 1
                    }}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column">
                    {
                        gifs.map((gif,index) => (
                            <SkeletonImage
                                key={index}
                                url={gif.media[0].tinygif.url}
                                id={gif.id}
                                dimension={gif.media[0].tinygif.dims}
                                addMessage={addMessage}
                                toggleModal={toggleModal}
                            />
                        ))
                    }
                </Masonry>
            )
        }

        else if (loading) {
            return (
                <div style={{marginTop:10}}>
                    <Spin size="large" />
                </div>
            )
        }

        else if (error) {
            return(
                <div className={'text-center'}>
                    <img src={error_image} alt="error"/>
                    <p style={{marginTop:20, fontWeight: 'bold'}}>Oups... une erreur est survenue</p>
                </div>
            )
        }

        else {
            return(
                <div className={'text-center'}>
                    <img src={empty_image} alt="empty"/>
                    <p style={{marginTop:20, fontWeight: 'bold'}}>Oups, aucun résultat trouvé</p>
                    <p style={{marginTop:15, color: 'grey'}}>Corriger votre recherche ou essayer en une nouvelle</p>
                </div>
            )
        }
    }

    return (
        <Modal visible={visible} title="Sélectionner un GIF" width={'100%'} style={{ top: 20 }} footer={null} onCancel={toggleModal}>
            <div className="input-group">
                <span className="input-group-btn">
                    <button type="button" className="btn btn-info">
                         <i className="fa fa-search" aria-hidden="true"></i>
                    </button>
                </span>
                <input type="text" className="form-control" placeholder="Rechercher un GIF ..." autoComplete={'off'} required onChange={handleSearch}/>
            </div>
            <Divider />
            <div style={{overflow:'auto'}} className={'text-center'}>
                { showResults() }
            </div>
        </Modal>
    )
}