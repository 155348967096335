import config from '../config';
import axios from "axios";

const API_TOKEN = config.tenor.api_key;
const API_URL = 'https://api.tenor.com/v1';

const client = axios.create({
    baseURL: API_URL,
    responseType: "json",
});

// Trending

export function listTrendingGifs () {

    return client.get('/trending' , {
        params : {
            key : API_TOKEN,
            limit : 25,
            media_filter : 'minimal'
        }
    })
}

// Search

export function searchGifs (query) {

    return client.get('/search' , {
        params : {
            key : API_TOKEN,
            q: query,
            limit : 25,
            media_filter : 'minimal'
        }
    });
}

