import React, {useState, Fragment} from 'react';
import GifModal from './GifModal'

export default function Form ({addMessage}) {

    const [modalVisible, setModalVisible] = useState(false);

    const handleSubmit = event => {
        event.preventDefault();

        const message_input = document.getElementById('message_input');
        const message = message_input.value;

        if (message) {
            addMessage(message);
            message_input.value = '';
        }
    };

    const toggleModal = () => {
        setModalVisible(modalVisible => !modalVisible)
    }

    return (
        <Fragment>
            <div id={'form-container'} className="col-md-11 col-sm-10 col-xs-9">
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <span className="input-group-btn">
                            <button type="button" className="btn btn-primary" onClick={toggleModal}>
                                <span>GIF</span>
                            </button>
                        </span>
                        <input id="message_input" type="text" className="form-control" placeholder="Ecrivez un message ..." autoComplete={'off'} autoFocus required maxLength={255} />
                        <span className="input-group-btn">
                            <button className="btn btn-success" type="submit">
                                <i className="fa fa-paper-plane-o" aria-hidden="true"></i>
                                <span className="hidden-xs">&nbsp;&nbsp;Envoyer</span>
                            </button>
                        </span>
                    </div>
                </form>
            </div>
            <GifModal visible={modalVisible} toggleModal={toggleModal} addMessage={addMessage} />
        </Fragment>
    )
}