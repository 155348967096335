import React from 'react';

export default function User ({id, sessions, currentUser}) {

    return (
        <div className="animated fadeInLeft member">
            { (id === currentUser) ? <p style={{marginTop:10,fontSize: 13,color: 'cornflowerblue', fontWeight:'bold'}}>Moi</p> : null}
            <img width="100" height="100" src={'https://avatars.dicebear.com/api/bottts/user-' + id + '.svg'} className="img-responsive img-user" alt={'avatar user ' + id}/>
            { (sessions > 1 ? <p style={{marginTop:10,fontSize: 11,color: 'darkmagenta'}}> {sessions} sessions ouvertes</p> : null ) }
            <hr className="user-hr"/>
        </div>
    )
}
