import React from 'react';
import moment from "moment/moment";
import 'moment/locale/fr'

export default function Message ({data, currentUser}) {

    const message_class = (parseInt(currentUser) === parseInt(data.user_id)) ? 'my-message' : 'other-message';

    return (
        <div className={'message ' + message_class}>
            <img src={'https://avatars.dicebear.com/api/bottts/user-' + data.user_id + '.svg'} className="img-responsive avatar" alt={'avatar ' + data.user_id}/>
                <div className="message-container text-center">
                    {
                        (data.type === 'text') ?
                            <div className="text">{data.message}</div> :
                            <img style={{marginBottom:10, borderRadius: '10px', border: '1px solid #ccc'}} src={data.message} alt={data.id}/>
                    }
                    <div className="date">{moment(data.created_at).format('D MMMM - HH:mm')}</div>
                </div>
        </div>
    )
}